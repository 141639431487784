<template>
  <CreateUpdateTemplate
    :customClass="'package-create'"
    v-if="getPermission('package:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="packageId && packageId > 0">Update</template
        ><template v-else>Create</template> new Package
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Package
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="packageForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <v-container fluid>
            <v-row>
              <v-col md="12" class="pb-0">
                <v-row>
                  <v-col md="8" class="pb-0">
                    <label class="font-weight-700 font-size-16 required"
                      >Name</label
                    >
                    <v-text-field
                      dense
                      filled
                      label="Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="packageCreate.name"
                      :rules="[
                        validateRules.required(packageCreate.name, 'Name'),
                        validateRules.minLength(packageCreate.name, 'Name', 1),
                        validateRules.maxLength(
                          packageCreate.name,
                          'Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                    <label class="font-weight-700 font-size-16"
                      >Description</label
                    >
                    <TinyMCE v-model.trim="packageCreate.description"></TinyMCE>
                    <br />
                    <label class="font-weight-700 font-size-16"
                      >Things to Note</label
                    >
                    <TinyMCE
                      v-model.trim="packageCreate.things_to_note"
                    ></TinyMCE>
                    <br />
                    <div class="d-flex">
                      <label class="font-weight-700 font-size-16 width-100"
                        >Reference #</label
                      >

                      <label class="px-4 font-weight-700 font-size-16 width-100"
                        >Package #</label
                      >
                      <label class="font-weight-700 font-size-16 width-100"
                        >Unit</label
                      >
                    </div>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        label="Reference"
                        solo
                        flat
                        :rules="[
                          validateRules.minLength(
                            packageCreate.reference,
                            'Reference',
                            1
                          ),
                          validateRules.maxLength(
                            packageCreate.reference,
                            'Reference',
                            100
                          ),
                        ]"
                        class="width-100"
                        v-model.trim="packageCreate.reference"
                      ></v-text-field>
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        label="Barcode"
                        solo
                        flat
                        class="px-4 width-100"
                        readonly
                        v-model.trim="packageCreate.barcode"
                      ></v-text-field>
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="unitList"
                        label="Unit"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="manageUnitDialog = true"
                        item-text="text"
                        item-value="id"
                        class="width-100"
                        v-model.trim="packageCreate.unit"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Unit(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div class="d-flex">
                      <label class="font-weight-700 font-size-16 width-100"
                        >Supplier Cost
                      </label>
                      <label class="px-4 font-weight-700 font-size-16 width-100"
                        >Price
                      </label>
                      <label class="font-weight-700 font-size-16 width-100"
                        >Net Price
                      </label>
                    </div>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        label="Supplier Cost"
                        solo
                        flat
                        class="width-100"
                        type="number"
                        min="0"
                        max="9999"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="packageCreate.company_cost"
                        v-on:keypress="
                          limitDecimal($event, packageCreate.company_cost)
                        "
                      ></v-text-field>
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        label="Price"
                        solo
                        flat
                        class="px-4 width-100"
                        type="number"
                        min="0"
                        max="9999"
                        v-on:keypress="
                          limitDecimal($event, packageCreate.charges)
                        "
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="packageCreate.charges"
                      ></v-text-field>
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        label="Net Price"
                        solo
                        flat
                        class="width-100"
                        type="number"
                        min="0"
                        max="9999"
                        v-on:keypress="
                          limitDecimal($event, packageCreate.net_price)
                        "
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="packageCreate.net_price"
                      ></v-text-field>
                    </div>
                    <template v-if="false">
                      <div class="d-flex">
                        <label
                          class="pr-2 font-weight-700 font-size-16 width-100"
                          >Purchase Cost</label
                        >

                        <label
                          class="pl-2 font-weight-700 font-size-16 width-100"
                          >Price</label
                        >
                      </div>
                      <div class="d-flex">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          label="Purchase Cost"
                          solo
                          flat
                          class="pr-2 width-100"
                          type="number"
                          min="0"
                          max="9999"
                          prepend-inner-icon="mdi-currency-usd"
                          v-model.trim="packageCreate.purchase_cost"
                          v-on:keypress="
                            limitDecimal($event, packageCreate.purchase_cost)
                          "
                        ></v-text-field>
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          label="Price"
                          solo
                          flat
                          class="pl-2 width-100"
                          type="number"
                          min="0"
                          max="9999"
                          v-on:keypress="
                            limitDecimal($event, packageCreate.selling_cost)
                          "
                          prepend-inner-icon="mdi-currency-usd"
                          v-model.trim="packageCreate.selling_cost"
                        ></v-text-field>
                      </div>
                    </template>
                    <div v-if="getPermission('attachment:create')">
                      <label class="custom-form-label width-100"
                        >Images
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="mx-2" v-bind="attrs" v-on="on"
                              >mdi-progress-question</v-icon
                            >
                          </template>
                          <span>More than 5 images are not allowed</span>
                        </v-tooltip>
                      </label>
                      <div>
                        <!-- <FileTemplate
                          allowUpload
                          isMinDisplay
                          :onlyImage="true"
                          :attachments.sync="attachment"
                          v-on:file:updated="updateAttachment"
                        ></FileTemplate> -->
                        <AttachmentWithName
                          v-on:file:updated="updateAttachment"
                          :label="true"
                          :attachments.sync="attachment"
                          allowUpload
                          :onlyImage="true"
                        ></AttachmentWithName>
                      </div>
                    </div>
                  </v-col>
                  <v-col v-if="false" md="8" class="pb-0 mt-8">
                    <v-menu
                      :close-on-content-click="false"
                      v-model="isFocused"
                      offset-y
                      content-class="max-width-836px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          label="Start typing then press enter..."
                          placeholder="Start typing then press enter..."
                          solo
                          flat
                          class="pr-2 width-100"
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                          v-on:click="isFocused = !isFocused"
                          v-on:keydown.esc="getLineItems(false, true)"
                          v-on:keydown.enter="getLineItems(false, true)"
                          v-on:keydown.tab="getLineItems(false, true)"
                          :loading="serverSearchLoading"
                          :disabled="serverSearchLoading"
                          v-model.trim="serverSearch"
                        ></v-text-field>
                      </template>
                      <v-list max-height="250px" v-if="!serverSearchLoading">
                        <template v-if="serverItemList.length">
                          <v-list-item
                            link
                            v-for="(item, index) in serverItemList"
                            v-on:click="selectPackage(item)"
                            :key="index"
                            :class="{
                              'light-green lighten-4': isItemExists(item),
                              'cursor-default pointer-events-none': item.header,
                            }"
                          >
                            <v-subheader v-if="item.header">{{
                              item.header
                            }}</v-subheader>
                            <template v-else>
                              <v-list-item-avatar
                                :color="
                                  lodash.isEmpty(item.primary_image)
                                    ? 'cyan'
                                    : 'white'
                                "
                                size="70"
                                class="headline font-weight-light white--text text-uppercase"
                              >
                                <template v-if="isItemExists(item)">
                                  <v-icon large color="white">mdi-check</v-icon>
                                </template>
                                <template
                                  v-else-if="
                                    lodash.isEmpty(item.primary_image) === false
                                  "
                                  ><v-img
                                    contain
                                    :src="$assetAPIURL(item.primary_image)"
                                  ></v-img
                                ></template>
                                <template v-else>{{
                                  item.name.charAt(0)
                                }}</template>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="font-size-16 text-truncate pb-1 text-capitalize"
                                  v-text="item.name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="item.description"
                                  class="font-size-14 text-truncate pb-1"
                                  v-text="item.description"
                                ></v-list-item-subtitle>
                                <template v-if="item.product_type == 'goods'">
                                  <v-list-item-subtitle
                                    class="font-size-14 pb-1"
                                  >
                                    <span class="font-weight-500"
                                      >Serial Number:
                                    </span>
                                    <template v-if="item.serial_number">
                                      {{ item.serial_number }}</template
                                    >
                                    <template v-else>No Serial Number</template>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    class="font-size-14 pb-1"
                                  >
                                    <span class="font-weight-500"
                                      >Part Number:
                                    </span>
                                    <template v-if="item.part_number">
                                      {{ item.part_number }}</template
                                    >
                                    <template v-else>No Part Number</template>
                                  </v-list-item-subtitle>
                                  <template v-if="false">
                                    <v-list-item-subtitle
                                      class="font-size-14 pb-1"
                                      ><span class="font-weight-500"
                                        >Price:</span
                                      >
                                      {{
                                        formatNumber(item.selling_cost)
                                      }}</v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="font-size-14"
                                      ><span class="font-weight-500"
                                        >Available Stock:</span
                                      >
                                      {{
                                        accountingUnFormat(item.available_stock)
                                      }}</v-list-item-subtitle
                                    >
                                  </template>
                                </template>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                        <template v-else>
                          <p
                            v-if="!serverSearchLoading"
                            class="m-0 py-4 row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              width="35"
                              :src="$assetURL('media/error/empty.png')"
                              class="mr-4 row-not-found-image"
                            />
                            Uhh... There are no {{ lineItemType }} at the
                            moment.
                          </p>
                        </template>
                      </v-list>
                    </v-menu>
                    <v-row v-if="packageItems.length">
                      <v-col
                        md="6"
                        v-for="(item, index) in packageItems"
                        :key="index"
                      >
                        <v-layout class="p-5 custom-grey-border">
                          <v-flex md3>
                            <v-img
                              contain
                              :src="$assetAPIURL(item.primary_image)"
                              :lazy-src="$defaultImage"
                              aspect-ratio="1"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-flex>
                          <v-flex md9 class="ml-4">
                            <h3>{{ item.name }}</h3>
                            <p
                              class="m-0 text--secondary custom-nowrap-ellipsis-three-line"
                            >
                              {{ item.description }}
                            </p>
                            <p class="m-0">
                              Price:
                              <b>{{ formatMoney(item.charges) }}</b>
                            </p>
                          </v-flex>
                        </v-layout>
                        <v-btn
                          v-on:click="removePackage(index)"
                          class="pkg-items-close"
                          icon
                          color="red lighten-1"
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </perfect-scrollbar>
        <ManageUnit
          :dialog.sync="manageUnitDialog"
          :unit.sync="unitList"
          v-on:close-dialog="manageUnitDialog = false"
          v-on:get-product-unit="getOptions"
        ></ManageUnit>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="package"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  PUT,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import {
  ErrorEventBus,
  InitializeError,
  ClearEventBus,
} from "@/core/lib/message.lib";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "package-create",
  title: "Create Package",
  data() {
    return {
      serverItemList: [],
      rawServerItemList: [],
      serverSearch: null,
      serverSearchLoading: true,
      pageLoading: true,
      barcodeDialog: false,
      isFocused: false,
      barcodeSetting: new Object(),
      attachment: new Array(),
      manageUnitDialog: false,
      packageCreated: false,
      packageId: null,
      searchTimeout: null,
      lineItemType: "services",
      searchTimeoutLimit: 500,
      unitList: new Array(),
      packageItems: new Array(),
      packageObject: new Object(),
      packageCreate: new Object({
        show_name: true,
        name: null,
        description: null,
        things_to_note: null,
        reference: null,
        barcode: null,
        company_cost: null,
        charges: null,
        net_price: null,
        unit: null,
        purchase_cost: null,
        selling_cost: null,
        pkg_items: [],
        product_images: [],
        product_type: "package",
      }),
    };
  },
  components: {
    ManageUnit,
    BarcodeSetting,
    TinyMCE,
    CreateUpdateTemplate,
    AttachmentWithName,
  },
  watch: {
    serverSearch(param) {
      if (!param) {
        this.getLineItems();
      }
    },
  },
  methods: {
    calculate_package_total() {
      const total_charges = this.lodash.map(this.packageItems, "charges");
      const total_company_cost = this.lodash.map(
        this.packageItems,
        "company_cost"
      );
      this.packageCreate.company_cost = this.lodash.sum(total_company_cost);
      this.packageCreate.charges = this.lodash.sum(total_charges);
    },
    isItemExists(item) {
      return this.lodash.find(this.packageItems, { id: item.id });
    },
    selectPackage(row) {
      if (row && !row.header) {
        const index = this.lodash.findIndex(this.packageItems, { id: row.id });
        if (index < 0) {
          this.packageItems.push(row);
          this.calculate_package_total();
        } else {
          this.removePackage(index);
        }
      }
    },
    removePackage(index) {
      this.packageItems.splice(index, 1);
      this.calculate_package_total();
    },
    calculateTotal() {
      /*let purchaseCost = 0;
      let sellingCost = 0;
      for (let i = 0; i < this.packageItems.length; i++) {
        purchaseCost = purchaseCost + this.packageItems[i].purchase_cost;
        sellingCost = sellingCost + this.packageItems[i].selling_cost;
      }
      this.packageCreate.purchase_cost = purchaseCost;
      this.packageCreate.selling_cost = sellingCost;*/
    },
    updateAttachment(param) {
      this.packageCreate.product_images = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: {
            product_type: "package",
          },
        })
        .then(({ data }) => {
          console.log({ data });
          _this.unitList = data.uomlist;
          _this.packageCreate.barcode = data.barcode;
          _this.barcodeSetting = data.barcode_setting;
          _this.packageCreate.description = _this.barcodeSetting.description;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;

          _this.packageId = _this.lodash.toSafeInteger(
            _this.$route.query.duplicate
          );
          if (_this.packageId && _this.packageId > 0) {
            _this.getPackageDetail();
          }

          if (_this.$route.name === "admin.package.update") {
            _this.packageId = _this.lodash.toSafeInteger(
              _this.$route.params.id
            );
            if (_this.packageId && _this.packageId > 0) {
              _this.getPackageDetail();
            }
          }
        });
    },
    getPackageDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "package/" + _this.packageId,
        })
        .then(({ data }) => {
          _this.packageCreate = new Object({
            name: data.name || null,
            description: data.description || null,
            things_to_note: data.things_to_note || null,
            reference: data.reference || null,
            barcode: data.barcode || null,
            purchase_cost: data.purchase_cost || null,
            selling_cost: data.selling_cost || null,
            company_cost: data.company_cost || null,
            charges: data.charges || null,
            net_price: data.net_price || null,
            unit: data.uom_id || null,
            pkg_items: [],
            product_images: [],
            product_type: "package",
          });
          _this.packageItems = data.package_items
            ? data.package_items.map((row) => row.package)
            : [];
          _this.attachment = data.product_images ? data.product_images : [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.packageForm.validate();

      const formErrors = _this.validateForm(_this.$refs.packageForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;

      ClearEventBus.$emit("clear:success", true);
      ClearEventBus.$emit("clear:error", true);

      _this.$store.dispatch(CLEAR_ERROR, {});

      _this.packageCreate.pkg_items = _this.packageItems.map((row) => row.id);

      let productUOM = _this.lodash.find(_this.unitList, {
        id: _this.packageCreate.unit,
      });

      let formData = new Object({
        show_name: _this.packageCreate.show_name || false,
        name: _this.packageCreate.name || null,
        description: _this.packageCreate.description || null,
        things_to_note: _this.packageCreate.things_to_note || null,
        reference: _this.packageCreate.reference || null,
        barcode: _this.packageCreate.barcode || null,
        company_cost: _this.packageCreate.company_cost || null,
        charges: _this.packageCreate.charges || null,
        net_price: _this.packageCreate.net_price || null,
        purchase_cost: _this.packageCreate.purchase_cost || null,
        selling_cost: _this.packageCreate.selling_cost || null,
        pkg_items: _this.packageCreate.pkg_items || [],
        attachments: _this.packageCreate.product_images || [],
        product_type: _this.packageCreate.product_type,
        uom_id: productUOM ? productUOM.id : 0,
        uom_value: productUOM ? productUOM.value : 0,
        uom_text: productUOM ? productUOM.text : null,
        confirmed: 1,
        sellable: 1,
        taxable: 0,
      });

      try {
        let requestURL = "package";
        let requestType = POST;

        if (_this.$route.name === "admin.package.update" && _this.packageId) {
          requestURL = `package/${_this.packageId}`;
          requestType = PUT;
        }

        _this.packageObject = await _this.$store.dispatch(requestType, {
          url: requestURL,
          data: formData,
        });

        if (_this.lodash.isEmpty(_this.packageObject) === false) {
          _this.packageId = _this.lodash.toSafeInteger(
            _this.packageObject.data.id
          );
          _this.backForce = true;
          _this.$router.push(
            _this.getDefaultRoute("package.detail", {
              params: {
                id: _this.packageId,
              },
            })
          );
        }
      } catch (error) {
        _this.logError(error);
      }

      _this.formLoading = false;
    },
    getLineItems(force, focus) {
      const _this = this;
      const search = _this.serverSearch ?? null;
      if (!force && !search && _this.serverItemList.length > 0) {
        return false;
      }
      const products =
        _this.lodash.toSafeInteger(_this.$route.query.product) ?? null;
      _this.serverSearchLoading = true;
      _this.serverItemList = [];
      _this.rawServerItemList = [];
      clearTimeout(_this.searchTimeout);
      _this.searchTimeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/get-line-items",
            data: {
              search,
              products,
              package: 1,
              line_item_type: _this.lineItemType,
            },
          })
          .then(({ data }) => {
            _this.serverItemList = data;
            _this.rawServerItemList = data;
            if (_this.isProduct && data) {
              const { id, description, part_number } = _this.lodash.find(data, [
                "id",
                products,
              ]);
              _this.packageCreate.product = id;
              _this.packageCreate.description = description;
              _this.packageCreate.part_number = part_number;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            if (focus) {
              _this.isFocused = true;
            }
            _this.serverSearchLoading = false;
          });
      }, _this.searchTimeoutLimit);
    },
  },
  created() {
    const _this = this;
    _this.getOptions();
  },
  mounted() {
    const _this = this;
    _this.getLineItems();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Package", route: "package" },
      { title: "Create" },
    ]);
  },
  computed: {
    isProduct() {
      return this.lodash.toSafeInteger(this.$route.query.product)
        ? true
        : false;
    },
  },
};
</script>
